import React, { Component } from "react";
import Fullvid from "../components/Fullvid";
import Header from "../components/header";
import Contentblock from "../components/contentblock";
import Textsection from "../components/textsection";

import Contact from "../components/forms/contact";
import Footer from "../components/footer";
export default class lottie extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
    this.getNestedObject = this.getNestedObject.bind(this);
  }
  async getData() {
    var res = await fetch("/deals/data.json", {
      headers: {
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: 0,
      },
    });
    var data = await res.json();
    return this.setState({ deals: data });
  }

  componentDidMount() {
    document.title = "Lottie";

    this.getData();
  }
  getNestedObject = (nestedObj, pathArr) => {
    return pathArr.reduce(
      (obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined),
      nestedObj
    );
  };
  eval2(data) {
    data = this.getNestedObject(this.state.deals, data);

    if (data !== undefined) {
      data = data.toLocaleString();
    } else {
      data = undefined;
    }
    return data;
  }
  render() {
    return (
      <div>
        <Header></Header>
        <Fullvid
          height="80vh"
          img="./img/lottieprofil4.png"
          displayImg={true}
          header="LOTTIE NORÉN"
          button={false}
          text="REG. FASTIGHETSMÄKLARE"
        ></Fullvid>
        <Contentblock>
          <Textsection
            header="Vem är Lottie?"
            paragraph="Letar du efter en fastighetsmäklare som kombinerar drivkraft, affärssinne och ett stort personligt engagemang? Möt Lottie Norén på Gbgfast AB. Lottie är en passionerad mäklare som aldrig lämnar något åt slumpen. Med en stark ambition att leverera en tjänst som inte bara möter, utan överträffar kundernas förväntningar. En bostadsförsäljning är en stor och betydelsefull händelse i livet. Lottie strävar efter att göra denna process till ett positivt och varaktigt minne. Välj Lottie Norén – En mäklare som sätter kunden först och bryr sig om varje detalj."
          ></Textsection>
          <Contentblock></Contentblock>
          <div
            id="form"
            className="container p-5"
            style={{ backgroundColor: "#fef3e4" }}
          >
            <Contact
              agentId="MDAtMXwwMDAwMDAxMjIzMnw0"
              agent={" Lottie?"}
            ></Contact>
          </div>{" "}
        </Contentblock>
        <Textsection
          header="Ett urval av tidigare försäljningar"
          paragraph=""
        ></Textsection>
        <div className="container fastighetgrid grid_2">
          {this.state.deals &&
            Object.keys(this.state.deals)
              .map((key, i) => {
                console.log(this.state.deals[key]);
                if (
                  this.state.deals[key]["publishingInformation"][
                    "isPreview"
                  ] === false &&
                  this.state.deals[key]["publishingInformation"][
                    "saleStatus"
                  ] === "ENUMS_SALE_STATUS_SOLD" &&
                  this.state.deals[key]["users"].filter(
                    (user) => user.id === "MDAtMXwwMDAwMDAxMjIzMnw0"
                  ).length > 0
                ) {
                  return (
                    <div
                      className="fastighet"
                      style={{ pointerEvents: "none" }}
                    >
                      <div className="fastighetimg">
                        <div
                          className="semer"
                          style={{
                            opacity: "1",
                            backgroundColor: "    background: rgba(0,0,0,0.8)",
                          }}
                        >
                          <p className="fs-4">Såld</p>
                        </div>

                        <img
                          alt="houseimg"
                          src={
                            "/deals/img/" +
                            this.state.deals[key]["id"].replace("..", "") +
                            "/main.jpg"
                          }
                        />
                      </div>
                      <div className="fastighetinfo">
                        <p>
                          {this.eval2([key, "location", "city"]) +
                            " / " +
                            this.eval2([key, "location", "district"])}
                        </p>
                        <h2>
                          {this.eval2([key, "location", "streetAddress"])}
                        </h2>
                        {this.eval2(["saleInformation", "finalPrice"]) && (
                          <p className="fs-4">
                            Pris:{" "}
                            {this.eval2([key, "saleInformation", "finalPrice"])}{" "}
                            kr
                          </p>
                        )}
                      </div>
                    </div>
                  );
                }
                return null;
              })
              .reverse()}
        </div>
        <div></div>

        <Contentblock>
          <Footer></Footer>
        </Contentblock>
      </div>
    );
  }
}
