import React, { Component } from 'react'
import Fullvid from '../components/Fullvid';
import Header from "../components/header";
import Contentblock from '../components/contentblock';
import Textsection from '../components/textsection';

import Contact from '../components/forms/contact';
import Footer from '../components/footer';
export default class alexander extends Component {
  constructor(props) { 
    super(props); 
    this.state = {
      isLoading:true,
    }; 
    this.getNestedObject = this.getNestedObject.bind(this)
} 
  async getData() {
    var res = await fetch("/deals/data.json", {
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': 0
      }
    });
    var data = await res.json();
    return this.setState({ deals:data});

  }

componentDidMount() {
  document.title = 'Alexander';

  this.getData();
}
getNestedObject = (nestedObj, pathArr) => {


  return pathArr.reduce((obj, key) =>
      (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
    

}
eval2(data) {
  data = this.getNestedObject(this.state.deals, data)

  if(data !== undefined) {
    data = data.toLocaleString()

  }
  else {
    data = undefined
  }
  return data
}
    render() {
        return(

                 <div>
            <Header></Header>
            <Fullvid height="80vh" img="./img/hasan Profil.jpg" displayImg={true} header="ALEXANDER IBRAHIM" button={false} text="Customer Service | Borådgivning"></Fullvid>
            <Contentblock>                
                <Textsection header="Vem är Alexander?" paragraph="En driven norrlänning som flyttat till västkustens pärla för att fullfölja sin dröm att bli fastighetsmäklare. Mitt arbetssätt präglas av noggrannhet, lyhördhet och stort personligt engagemang. Att köpa bostad kan vara en av livets största affärer, med min kompetens hoppas jag kunna hjälpa dig till ett lyckat resultat."></Textsection>
                <Contentblock>
                </Contentblock>
                </Contentblock>


            <Contentblock>
                <Footer></Footer>
            </Contentblock>
        </div>

    )
}
}