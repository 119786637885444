import React, { Component } from "react";

import Header from "../components/header";
import Fullvid from "../components/Fullvid";
import Contentblock from "../components/contentblock";
import Footer from "../components/footer";
import Card from "../components/card";
import FormModal from "../components/formmodal";
import Imagegrid2 from "../components/imagegrid2";
import { Link } from "react-router-dom";
import ColoredLine from "../components/coloredline";
import Contact from "../components/forms/contact";
import Map from "../components/map";
import Bookviewing from "../components/forms/bookviewing";
import Slideshow from "../components/slideshow";
import Field from "../components/field";
const trans_rent = {
  garage: "Garage",
  heating: "Värme",
  water: "Vatten",
  hotWater: "Varmvatten",
  coldWater: "Kallvatten",
  electricity: "El",
  cableTv: "Kabel-TV",
  internetAndTv: "Internet/TV",
  ipTelephony: "IP-Telefoni",
  ipTV: "IPTV",
  internet: "Internet",
  carport: "Carport",
  parkingSpace: "Parkeringsplats",
  condominiumSupplement: "Bostadsrättstillägg",
};

export default class objekt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      modalVisible: false,
      windowWidth: window.innerWidth,
      showingSlotId: "",
      showingId: "",
    };
    this.getNestedObject = this.getNestedObject.bind(this);
    this.handleToggle = React.createRef();
    this.openShow = React.createRef();
    this.buttonClick = this.buttonClick.bind(this);
    this.showFull = React.createRef();
  }

  getMainImage() {
    var url = "";
    this.state.deal["images"].map((img) =>
      img["isMainImage"] === true ? (url = img["viewUrl"]) : null
    );
    return url;
  }
  getNestedObject = (nestedObj, pathArr) => {
    return pathArr.reduce(
      (obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined),
      nestedObj
    );
  };

  getValue(data = "", title = "", suffix = "", prefix = "") {
    data = this.getNestedObject(this.state.deal, data);

    if (data) {
      return (
        <p className="fs-5   mb-1">
          <span className="fs-5 fw-bolder m-0">
            {title ? title + ":" : null}{" "}
          </span>
          {prefix + data.toLocaleString() + " " + suffix}
          <br />{" "}
        </p>
      );
    }
  }

  eval2(data) {
    data = this.getNestedObject(this.state.deal, data);
    if (data === undefined) {
      return "";
    }
    return data;
  }

  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  parseDate(date) {
    var d = new Date(date);
    var clock =
      (d.getHours() < 10 ? "0" : "") +
      d.getHours() +
      ":" +
      (d.getMinutes() < 10 ? "0" : "") +
      d.getMinutes();
    console.log(clock);
    d = d.toISOString().substring(0, 10) + " " + clock;
    return d;
  }
  async getData() {
    var res = await fetch("/deals/data.json", {
      headers: {
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: 0,
      },
    });
    var data = await res.json();

    var linkID = this.props.match.params.objektId;
    if (!linkID.includes("..")) {
      linkID = linkID + "..";
    }
    if (data[linkID]["location"]) {
      document.title = data[linkID]["location"]["streetAddress"];
    } else {
      document.title = "Gbgfast";
    }

    return this.setState({ deal: data[linkID] });
  }
  buttonClick(slotId, Id) {
    this.handleToggle.current.modalToggle();
    this.setState({ showingSlotId: slotId, showingId: Id });
  }
  openSlideShow(index) {
    this.openShow.current.showToggleOn(index);
  }
  openSlideShowFP() {
    this.openShow.current.showToogleFP();
  }
  openFullShow() {
    this.showFull.current.showFullBtn();
    this.openShow.current.showToggleFP();
  }

  getMainRealtor(realtors) {
    let tmpRealtor = "";

    realtors.forEach((element) => {
      if (element?.isMainUser) {
        tmpRealtor = element;
      }
    });

    if (tmpRealtor) {
      return tmpRealtor.firstName;
    } else {
      return null;
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);

    this.getData();
  }

  render() {
    let _this = this;

    return (
      <div id="head">
        {this.state.deal && (
          <div>
            <Slideshow
              id={this.state.deal["id"]}
              ref={this.openShow}
              images={this.state.deal["images"]}
            ></Slideshow>

            <FormModal ref={this.handleToggle}>
              <Bookviewing
                showingId={this.state.showingId}
                showingSlotId={this.state.showingSlotId}
                dealId={this.state.deal["id"]}
              ></Bookviewing>
            </FormModal>
            <Header></Header>

            <Fullvid
              height="80vh"
              arrow={false}
              img={
                "/deals/img/" +
                this.state.deal["id"].replace("..", "") +
                "/main.jpg"
              }
              header={this.eval2(["location", "streetAddress"])}
              button={false}
              text={this.eval2(["location", "residentialArea"])}
              displayImg={true}
            >
              <div className="navbarObjekt">
                <div
                  className="navbarObjektText "
                  style={{ letterSpacing: "3px" }}
                >
                  <a
                    href="#content1"
                    style={{ marginLeft: "2vw", marginRight: "2vw" }}
                  >
                    Översikt
                  </a>
                  <a
                    href="#bilder"
                    style={{ marginLeft: "2vw", marginRight: "2vw" }}
                  >
                    Bilder
                  </a>
                  <a
                    onClick={() => {
                      this.openFullShow();
                    }}
                    href="#bilder"
                    style={{ marginLeft: "2vw", marginRight: "2vw" }}
                  >
                    Planlösning
                  </a>
                  <a
                    href="#fakta"
                    style={{ marginLeft: "2vw", marginRight: "2vw" }}
                  >
                    Fakta
                  </a>
                </div>
              </div>
            </Fullvid>

            {this.state.deal["bidding"]["isActive"] && (
              <div
                style={{
                  position: "absolute",
                  bottom: "35%",
                  right: "0px",
                  width: "300px",
                  height: "65px",
                  backgroundColor: "rgba(0,0,0,0.6)",
                  zIndex: "1",
                  borderRadius: "5px 0 0 5px",
                  padding: "1rem",
                  color: "white",
                }}
              >
                <p className="fs-5 m-0 mb-2">BUDGIVNING PÅGÅR</p>
              </div>
            )}
            {this.state.deal.publishingInformation.saleStatus ===
              "ENUMS_SALE_STATUS_COMING" && (
              <div
                style={{
                  position: "absolute",
                  bottom: "35%",
                  right: "0px",
                  width: "300px",
                  height: "65px",
                  backgroundColor: "rgba(0,0,0,0.6)",
                  zIndex: "1",
                  borderRadius: "5px 0 0 5px",
                  padding: "1rem",
                  color: "white",
                }}
              >
                <p className="fs-5 m-0 mb-2">SNART TILL SALU</p>
              </div>
            )}

            <div id="content1" className="pt-4">
              <div className="container-xl" style={{ maxWidth: "1600px" }}>
                <div className="row gx-5 mk-5">
                  <div className="col-md px-4 mt-4">
                    <div>
                      <p className="fs-5   m-0 pb-1 text-uppercase font-serif">
                        {this.eval2(["location", "city"]) +
                          (this.eval2(["location", "district"]) ? " / " : "") +
                          this.eval2(["location", "district"])}
                      </p>
                      <ColoredLine color="#f9b361"></ColoredLine>

                      <h2 className=" mt-3">
                        {this.eval2(["location", "streetAddress"])}
                      </h2>
                      {this.state.deal["bidding"]["isActive"] &&
                        this.eval2(["bidding", "bids"]).length >= 1 && (
                          <p className="fs-5 m-0 mb-2">
                            Högsta bud:{" "}
                            <span style={{ color: "#f9b361" }}>
                              {this.state.deal["bidding"]["bids"][
                                this.state.deal["bidding"]["bids"].length - 1
                              ]["amount"].toLocaleString() + " kr"}
                            </span>
                          </p>
                        )}
                      <p className="fs-5">
                        {this.eval2(["saleInformation", "price"])
                          ? this.eval2([
                              "saleInformation",
                              "priceType",
                            ]).toLocaleString() +
                            ": " +
                            this.eval2([
                              "saleInformation",
                              "price",
                            ]).toLocaleString() +
                            " kr"
                          : ""}
                      </p>
                      <p className="fs-5   m-0 pb-1 font-serif text-uppercase">
                        snabbfakta
                      </p>
                      <ColoredLine color="#f9b361"></ColoredLine>
                      <div className="container-fluid p-0 mt-3 objectFacts">
                        <div className="row">
                          {this.getValue(
                            ["economy", "costs", "rent"],
                            "Avgift",
                            "kr"
                          )}
                          {this.getValue(
                            ["rooms", "numberOfRooms"],
                            "Rum",
                            "rum och kök"
                          )}
                          {this.getValue(
                            ["estate", "livingArea"],
                            "Boarea",
                            "kvm"
                          )}
                          {this.getValue(
                            ["estate", "otherLivingArea"],
                            "Biarea",
                            "kvm"
                          )}
                          {this.getValue(
                            ["estate", "plotArea"],
                            "Tomtarea",
                            "kvm"
                          )}
                          {this.getValue(
                            ["estate", "floor"],
                            "Våning",
                            "av " + this.eval2(["estate", "floorsInTotal"])
                          )}
                          {this.eval2(["objectType", "constant"]) ===
                          "OBJECT_TYPE_TENANT_OWNERSHIP"
                            ? this.getValue(
                                ["economy", "costs", "operatingCostSum"],
                                "Driftskostnad",
                                "kr/mån"
                              )
                            : this.getValue(
                                ["economy", "costs", "operatingCostSum"],
                                "Driftskostnad",
                                "kr/år"
                              )}
                          {this.getValue(["estate", "buildYear"], "Byggnadsår")}
                        </div>
                        {this.state.deal["bidding"]["isActive"] &&
                          this.eval2(["bidding", "bids"]).length >= 1 && (
                            <div>
                              <p className="fs-5   m-0 mt-3 pb-1 font-serif text-uppercase">
                                BUDHISTORIK
                              </p>
                              <ColoredLine color="#f9b361"></ColoredLine>
                              <div className="row">
                                <div className="col text-start">
                                  <p className="fs-6   fw-normal m-0">Bud</p>
                                </div>
                                <div className="col-3">
                                  <p className="fs-6   fw-normal m-0">
                                    Budgivare
                                  </p>
                                </div>
                                <div className="col">
                                  <p className="fs-6   fw-normal m-0">Tid</p>
                                </div>
                              </div>
                            </div>
                          )}
                        {this.state.deal["bidding"]["isActive"] &&
                          this.eval2(["bidding", "bids"]).length >= 1 && (
                            <div className="row">
                              <div className="col">
                                <p className="fs-6 m-0 p-0">
                                  {this.state.deal["bidding"]["bids"][
                                    this.state.deal["bidding"]["bids"].length -
                                      1
                                  ]["amount"].toLocaleString()}{" "}
                                  kr
                                </p>
                              </div>
                              <div className="col-3">
                                <p className="fs-6 m-0 p-0">
                                  {
                                    this.state.deal["bidding"]["bids"][
                                      this.state.deal["bidding"]["bids"]
                                        .length - 1
                                    ]["alias"]
                                  }
                                </p>
                              </div>
                              <div className="col">
                                <p className="fs-7 m-0 p-0">
                                  {this.parseDate(
                                    this.state.deal["bidding"]["bids"][
                                      this.state.deal["bidding"]["bids"]
                                        .length - 1
                                    ]["date"]
                                  )}
                                </p>
                              </div>
                            </div>
                          )}
                        {this.state.deal["bidding"]["isActive"] &&
                          this.eval2(["bidding", "bids"]).length >= 1 &&
                          this.state.deal["bidding"]["bids"]
                            .slice(0, -1)
                            .map(function (item, i) {
                              var d = _this.parseDate(item["date"]);
                              return (
                                <div key={i} className="row">
                                  <div className="col">
                                    <p className="fs-6 m-0 p-0">
                                      {item["amount"].toLocaleString()} kr
                                    </p>
                                  </div>
                                  <div className="col-3">
                                    <p className="fs-6 m-0 p-0">
                                      {item["alias"]}
                                    </p>
                                  </div>
                                  <div className="col">
                                    <p className="fs-7 m-0 p-0">{d}</p>
                                  </div>
                                </div>
                              );
                            })}
                      </div>
                    </div>
                    {this.state.windowWidth < 1400 && (
                      <div className="mb-5">
                        {this.state.deal["viewings"].length > 0 && (
                          <div>
                            <p className="fs-5 font-serif   mt-3 m-0 pb-1 text-uppercase">
                              Visningar
                            </p>
                            <ColoredLine color="#f9b361"></ColoredLine>
                          </div>
                        )}
                        {this.state.deal["viewings"].map(function (item, i) {
                          var finalRender = [];
                          if (
                            item.type === "ENUMS_VIEWINGS_TYPE_DATE_AND_TIME"
                          ) {
                            var sd = new Date(item["endDate"]);
                            var clock =
                              sd.getHours() +
                              ":" +
                              (sd.getMinutes() < 10 ? "0" : "") +
                              sd.getMinutes();

                            var d = _this.parseDate(item["startDate"]);

                            finalRender.push(
                              <div key={i} className="container-fluid p-0 m-0">
                                <div className="row mt-3">
                                  <div className="col m-auto">
                                    <p className="fs-5 m-0 p-0">
                                      {d + "-" + clock + " " + item.description}
                                    </p>
                                  </div>
                                  <div className="col text-start">
                                    <div
                                      onClick={() =>
                                        _this.buttonClick(undefined, item.id)
                                      }
                                    >
                                      <Link to={{ hash: "#head" }}>
                                        <input
                                          type="submit"
                                          value="Boka"
                                          className="btn"
                                          style={{
                                            width: "70px",
                                            height: "40px",
                                            padding: "0",
                                          }}
                                        />
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          } else if (
                            item.type === "ENUMS_VIEWINGS_TYPE_TIME_SLOTS"
                          ) {
                            item.slots.map(function (item2, i2) {
                              var d = new Date(item2["startTime"]);
                              d = d.toString();
                              d = d.split(" GMT")[0];
                              finalRender.push(
                                <div
                                  key={i2}
                                  className="container-fluid p-0 m-0"
                                >
                                  <div className="row mt-3">
                                    <div className="col m-auto">
                                      <p className="fs-5 m-0 p-0">
                                        {d +
                                          " Lediga platser: " +
                                          item2.freeSpots +
                                          "/" +
                                          item.slotCapacity}
                                      </p>
                                    </div>
                                    <div className="col text-start">
                                      <div
                                        onClick={() =>
                                          _this.buttonClick(item2.id, item.id)
                                        }
                                      >
                                        <Link to={{ hash: "#head" }}>
                                          <input
                                            type="submit"
                                            value="Boka"
                                            className="btn"
                                            style={{
                                              width: "70px",
                                              height: "40px",
                                              padding: "0",
                                            }}
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                              return null;
                            });
                          } else if (
                            item.type === "ENUMS_VIEWINGS_TYPE_CONTACT_BROKER"
                          ) {
                            return (
                              <p className="fs-5 mt-3">
                                Kontakta mäklaren för visning
                              </p>
                            );
                          }

                          return finalRender;
                        })}

                        <div className="mt-5">
                          {this.state.deal["users"].map(function (item, i) {
                            if (item.isMainUser) {
                              return (
                                <Card
                                  background="rgba(254, 243, 228, 0.6)"
                                  width="100%"
                                  margin="auto"
                                  key={i}
                                  img={"/img/" + item.id + ".png"}
                                  isMainUser="Ansvarig märklare"
                                  title={item.firstName + " " + item.lastName}
                                  paddingLeft="2rem"
                                  yrke="Reg. Fastighetsmäklare"
                                  desc={item.email}
                                  tel={item.phoneNumber}
                                ></Card>
                              );
                            }
                            return null;
                          })}
                          <div className="mt-4">
                            {this.state.deal["users"].map(function (item, i) {
                              if (!item.isMainUser) {
                                return (
                                  <Card
                                    background="rgba(254, 243, 228, 0.6)"
                                    width="100%"
                                    margin="auto"
                                    key={i}
                                    img={"/img/" + item.id + ".png"}
                                    isMainUser="Assisterande mäklare"
                                    title={item.firstName + " " + item.lastName}
                                    paddingLeft="2rem"
                                    yrke="Reg. Fastighetsmäklare"
                                    desc={item.email}
                                    tel={item.phoneNumber}
                                  ></Card>
                                );
                              }
                              return null;
                            })}
                          </div>
                          <div className="w-100 text-center mt-5 mb-5">
                            <Link to={{ hash: "#formulär" }}>
                              <input
                                type="submit"
                                value="Kontakta mäklaren"
                                className="btn text-center"
                                style={{ height: "40px", padding: "0" }}
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-md">
                    <div className="mt-4">
                      <p
                        className="fs-5 font-serif   mt-3 m-0 pb-1 "
                        style={{ letterSpacing: "1px" }}
                      >
                        {"sellingTexts" in this.state.deal &&
                          "sellingTextSubject" in
                            this.state.deal["sellingTexts"] &&
                          this.state.deal["sellingTexts"]["sellingTextSubject"]}
                      </p>
                      <div className="container-fluid p-0">
                        <div className="row mt-4">
                          <ul>
                            {"sellingTexts" in this.state.deal &&
                              "sellingTextShort" in
                                this.state.deal["sellingTexts"] &&
                              this.state.deal["sellingTexts"][
                                "sellingTextShort"
                              ]
                                .split("- ")
                                .map(function (item, i) {
                                  if (i !== 0) {
                                    return (
                                      <li key={i}>
                                        <span className="fst-italic">
                                          {item}
                                        </span>
                                      </li>
                                    );
                                  }
                                  return null;
                                })}
                          </ul>

                          <div className="col"></div>
                        </div>
                        <div className="row mt-4">
                          <p
                            className="fs-5  "
                            style={{ whiteSpace: "pre-line" }}
                          >
                            {"sellingTexts" in this.state.deal &&
                              "sellingText" in
                                this.state.deal["sellingTexts"] &&
                              this.state.deal["sellingTexts"]["sellingText"]}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl">
                    <div className="">
                      {this.state.windowWidth >= 1400 && (
                        <div className="mt-4">
                          {this.state.deal["viewings"].length > 0 && (
                            <div>
                              <p className="fs-5 font-serif   m-0 pb-1 text-uppercase">
                                Visningar
                              </p>
                              <ColoredLine color="#f9b361"></ColoredLine>
                            </div>
                          )}

                          {this.state.deal["viewings"].map(function (item, i) {
                            var finalRender = [];
                            if (
                              item.type === "ENUMS_VIEWINGS_TYPE_DATE_AND_TIME"
                            ) {
                              var sd = new Date(item["endDate"]);
                              var clock =
                                sd.getHours() +
                                ":" +
                                (sd.getMinutes() < 10 ? "0" : "") +
                                sd.getMinutes();

                              var d = _this.parseDate(item["startDate"]);

                              finalRender.push(
                                <div
                                  key={i}
                                  className="container-fluid p-0 m-0"
                                >
                                  <div className="row mt-3">
                                    <div className="col m-auto">
                                      <p className="fs-5 m-0 p-0">
                                        {d +
                                          "-" +
                                          clock +
                                          " " +
                                          (item.description
                                            ? item.description
                                            : "")}
                                      </p>
                                    </div>
                                    <div className="col text-start">
                                      <div
                                        onClick={() =>
                                          _this.buttonClick(undefined, item.id)
                                        }
                                      >
                                        <Link to={{ hash: "#head" }}>
                                          <input
                                            type="submit"
                                            value="Boka"
                                            className="btn"
                                            style={{
                                              width: "70px",
                                              height: "40px",
                                              padding: "0",
                                            }}
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            } else if (
                              item.type === "ENUMS_VIEWINGS_TYPE_TIME_SLOTS"
                            ) {
                              item.slots.map(function (item2, i2) {
                                var d = new Date(item2["startTime"]);
                                d = d.toString();
                                d = d.split(" GMT")[0];
                                finalRender.push(
                                  <div
                                    key={i2}
                                    className="container-fluid p-0 m-0"
                                  >
                                    <div className="row mt-3">
                                      <div className="col m-auto">
                                        <p className="fs-5 m-0 p-0">
                                          {d +
                                            " Lediga platser: " +
                                            item2.freeSpots +
                                            "/" +
                                            item.slotCapacity}
                                        </p>
                                      </div>
                                      <div className="col text-start">
                                        <div
                                          onClick={() =>
                                            _this.buttonClick(item2.id, item.id)
                                          }
                                        >
                                          <Link to={{ hash: "#head" }}>
                                            <input
                                              type="submit"
                                              value="Boka"
                                              className="btn"
                                              style={{
                                                width: "70px",
                                                height: "40px",
                                                padding: "0",
                                              }}
                                            />
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                                return null;
                              });
                            } else if (
                              item.type === "ENUMS_VIEWINGS_TYPE_CONTACT_BROKER"
                            ) {
                              return (
                                <p className="fs-5 mt-3">
                                  Kontakta mäklaren för visning
                                </p>
                              );
                            }

                            return finalRender;
                          })}
                          <div className="mt-4">
                            {this.state.deal["users"].map(function (item, i) {
                              console.log(i);
                              if (item.isMainUser) {
                                return (
                                  <Card
                                    background="rgba(254, 243, 228, 0.6)"
                                    key={i}
                                    img={"/img/" + item.id + ".png"}
                                    isMainUser="Ansvarig mäklare"
                                    title={item.firstName + " " + item.lastName}
                                    paddingLeft="2rem"
                                    yrke="Reg. Fastighetsmäklare"
                                    desc={item.email}
                                    tel={item.phoneNumber}
                                  ></Card>
                                );
                              }
                              return null;
                            })}
                            <div className="mt-4">
                              {this.state.deal["users"].map(function (item, i) {
                                if (!item.isMainUser) {
                                  return (
                                    <Card
                                      background="rgba(254, 243, 228, 0.6)"
                                      key={i}
                                      img={"/img/" + item.id + ".png"}
                                      isMainUser="Assisterande mäklare"
                                      title={
                                        item.firstName + " " + item.lastName
                                      }
                                      paddingLeft="2rem"
                                      yrke="Reg. Fastighetsmäklare"
                                      desc={item.email}
                                      tel={item.phoneNumber}
                                    ></Card>
                                  );
                                }
                                return null;
                              })}
                            </div>
                          </div>
                          <div className="w-100 text-center">
                            <Link to={{ hash: "#formulär" }}>
                              <input
                                type="submit"
                                value="Kontakta mäklaren"
                                className="btn"
                                style={{
                                  height: "40px",
                                  padding: "0",
                                  marginTop: "20px",
                                }}
                              />
                            </Link>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <Contentblock></Contentblock>
            </div>
            <div id="bilder">
              {this.eval2(["estate", "otherDescription"]) && (
                <div className="container-lg text-center">
                  <iframe
                    className="objektVideo"
                    src={
                      "https://www.youtube.com/embed/" +
                      this.eval2(["estate", "otherDescription"])
                    }
                  ></iframe>
                </div>
              )}
              {this.eval2(["estate", "description"]) &&
                this.eval2(["objectType", "constant"]) ===
                  "OBJECT_TYPE_LAND" && (
                  <div className="container-lg text-center">
                    <iframe
                      className="objektVideo"
                      src={
                        "https://www.youtube.com/embed/" +
                        this.eval2(["estate", "description"])
                      }
                    ></iframe>
                  </div>
                )}
              {this.eval2([
                "energyPerformance",
                "descriptionMultiLang",
                "sv",
              ]) &&
                this.eval2(["objectType", "constant"]) ===
                  "OBJECT_TYPE_COMMERCIAL" && (
                  <div className="container-lg text-center">
                    <iframe
                      className="objektVideo"
                      src={
                        "https://www.youtube.com/embed/" +
                        this.eval2([
                          "energyPerformance",
                          "descriptionMultiLang",
                          "sv",
                        ])
                      }
                    ></iframe>
                  </div>
                )}
              {this.state.deal["images"].length > 0 && (
                <Imagegrid2
                  ref={this.showFull}
                  buttonFunc={this.openSlideShow.bind(this)}
                  id={this.state.deal["id"]}
                  images={this.state.deal["images"]}
                ></Imagegrid2>
              )}
              {/*<Contentblock>
                    <div>
                        <img className="props3" style={{filter: 'brightness(100%)'}} src="/img/planritning.jpg" />
                    </div>
                    
                </Contentblock>*/}
            </div>
            <div id="formulär">
              <div
                id="form"
                className="container p-5"
                style={{ backgroundColor: "#fef3e4" }}
              >
                <Contact
                  objekt={this.state.deal["id"]}
                  agent={
                    " " + this.getMainRealtor(this.state.deal["users"]) + "?"
                  }
                ></Contact>
              </div>
            </div>
            {this.eval2(["rooms", "rooms"]) &&
              this.state.deal.rooms.rooms.length > 0 && (
                <div>
                  <div className="container text-center w-25" id="fakta">
                    <h2 className="mt-5 fs-4">RUMSBESKRIVNING</h2>
                    <ColoredLine color="#f9b361"></ColoredLine>
                  </div>
                  <div className="container-sm faktawrapper">
                    {this.state.deal.rooms.rooms.map(function (item, i) {
                      return (
                        <div key={i}>
                          <p className="fs-5   m-0 pb-1 font-serif text-uppercase  mt-5">
                            {item.name}
                          </p>
                          <p className="fs-5   mb-1">{item.description}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            <div className="container text-center w-25" id="fakta">
              <h2 className="mt-5 fs-4">FAKTA</h2>
              <ColoredLine color="#f9b361"></ColoredLine>
            </div>
            <div>
              <div className="container-sm faktawrapper">
                <div>
                  <p className="fs-5   m-0 pb-1 font-serif text-uppercase  mt-5">
                    Beskrivning och Ekonomi
                  </p>

                  {this.getValue(["estate", "totalArea"], "Totalareal", "kvm")}
                  {this.getValue(
                    ["estate", "propertyDesignation"],
                    "Fastighetsbeteckning"
                  )}
                  {this.getValue(
                    ["saleInformation", "earliestAccessDateDescription"],
                    "Tillträde"
                  )}
                  {this.getValue(
                    ["saleInformation", "price"],
                    "Utgångspris",
                    "kr"
                  )}
                  {this.getValue(
                    ["economy", "costs", "rent"],
                    "Månadsavgift",
                    "kr"
                  )}
                  {this.getValue(
                    ["rooms", "numberOfRooms"],
                    "Rum",
                    "rum och kök"
                  )}
                  {this.getValue(
                    ["rooms", "numberOfBedrooms"],
                    "Sovrum",
                    "rum"
                  )}
                  {this.getValue(
                    ["rooms", "convertNumberOfBedRooms"],
                    "Möjlighet till antal sovrum",
                    "rum"
                  )}
                  {this.getValue(["estate", "livingArea"], "Boarea", "kvm")}
                  {this.getValue(
                    ["estate", "otherLivingArea"],
                    "Biarea",
                    "kvm"
                  )}
                  {this.getValue(
                    ["estate", "totalLivingArea"],
                    "Bruksarea",
                    "kvm"
                  )}
                  {this.getValue(["estate", "plotArea"], "Tomtarea", "kvm")}
                  {this.getValue(
                    ["estate", "floor"],
                    "Våning",
                    "av " + this.eval2(["estate", "floorsInTotal"])
                  )}
                  {this.eval2(["objectType", "constant"]) ===
                  "OBJECT_TYPE_TENANT_OWNERSHIP" ? (
                    <>
                      {this.getValue(
                        ["economy", "costs", "operatingCostSum"],
                        "Driftskostnad",
                        "kr/mån"
                      )}

                      {this.eval2(["economy", "costs", "operatingCostSum"]) && (
                        <p className="fs-5 mb-1">
                          <span className="fs-5 fw-bolder m-0">
                            Fördelning driftskostnader: <br />
                          </span>
                          {this.getValue(
                            ["economy", "personsInHousehold"],
                            "",
                            "",
                            "Personer i hushållet: "
                          )}
                          {this.getValue(
                            ["economy", "electricityConsumption"],
                            "",
                            "kWh/år",
                            "Årlig elförbrukning: "
                          )}
                          {this.getValue(
                            ["economy", "costs", "insurance"],
                            "",
                            "kr/mån",
                            "Hemförsäkring: "
                          )}
                          {this.getValue(
                            ["economy", "costs", "heating"],
                            "",
                            "kr/mån",
                            "Uppvärmning: "
                          )}
                          {this.getValue(
                            ["economy", "costs", "householdElectricity"],
                            "",
                            "kr/mån",
                            "Hushållsel: "
                          )}
                          {this.getValue(
                            ["economy", "costs", "condominiumSupplement"],
                            "",
                            "kr/mån",
                            "Bostadsrättstillägg: "
                          )}
                          {this.eval2(["economy", "costs", "otherCosts"]) && (
                            <div>
                              {this.state.deal.economy.costs.otherCosts.map(
                                function (item, i) {
                                  return (
                                    <div key={i}>
                                      <p className="fs-5   mb-1">
                                        {item.title + ": "}
                                        {item.amount.toLocaleString() +
                                          " kr/mån"}
                                      </p>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          )}
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      {this.getValue(
                        ["economy", "costs", "operatingCostSum"],
                        "Driftskostnad",
                        "kr/år"
                      )}
                      {this.eval2(["economy", "costs", "operatingCostSum"]) && (
                        <p className="fs-5 mb-1">
                          <span className="fs-5 fw-bolder m-0">
                            Fördelning driftskostnader: <br />
                          </span>
                          {this.getValue(
                            ["economy", "personsInHousehold"],
                            "",
                            "",
                            "Personer i hushållet: "
                          )}
                          {this.getValue(
                            ["economy", "electricityConsumption"],
                            "",
                            "kWh/år",
                            "Årlig elförbrukning: "
                          )}
                          {this.getValue(
                            ["economy", "costs", "insurance"],
                            "",
                            "kr/år",
                            "Hemförsäkring: "
                          )}
                          {this.getValue(
                            ["economy", "costs", "heating"],
                            "",
                            "kr/år",
                            "Uppvärmning: "
                          )}
                          {this.getValue(
                            ["economy", "costs", "householdElectricity"],
                            "",
                            "kr/år",
                            "Hushållsel: "
                          )}
                          {this.getValue(
                            ["economy", "costs", ""],
                            "",
                            "kr/år",
                            "Bostadsrättstillägg: "
                          )}
                          {this.getValue(
                            ["economy", "costs", "waterAndDrain"],
                            "",
                            "kr/år",
                            "Vatten och avlopp: "
                          )}
                          {this.getValue(
                            ["economy", "costs", "sanitation"],
                            "",
                            "kr/år",
                            "Renhållning: "
                          )}
                          {this.getValue(
                            ["economy", "costs", "electricity"],
                            "",
                            "kr/år",
                            "Elkostnad: "
                          )}
                          {this.getValue(
                            ["economy", "costs", "community"],
                            "",
                            "kr/år",
                            "Samfällighetskostnad: "
                          )}
                          {this.eval2(["economy", "costs", "otherCosts"]) && (
                            <div>
                              {this.state.deal.economy.costs.otherCosts.map(
                                function (item, i) {
                                  return (
                                    <div key={i}>
                                      <p className="fs-5   mb-1">
                                        {item.title + ": "}{" "}
                                        {item.amount.toLocaleString() +
                                          " kr/år"}
                                      </p>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          )}
                        </p>
                      )}
                    </>
                  )}

                  {this.getValue(
                    ["economy", "liftDescription"],
                    "Hiss kommentar"
                  )}
                  {this.eval2(["economy", "includedInRent"]) && (
                    <p className="fs-5 mb-1">
                      <span className="fs-5 fw-bolder m-0">
                        Ingår i avgiften:
                        <br />
                      </span>
                      {Object.entries(
                        this.eval2(["economy", "includedInRent"])
                      ).map((entry) => {
                        if (entry[1] == true) {
                          return (
                            <span>
                              {trans_rent[entry[0]]}
                              <br />
                            </span>
                          );
                        }
                      })}
                    </p>
                  )}

                  {this.getValue(
                    ["economy", "includedInRentDescription"],
                    "Beskrivning av kostnader"
                  )}
                  {this.getValue(
                    ["economy", "costs", "rentDescription"],
                    "Beskrivning av månadsavgift"
                  )}
                  {this.getValue(
                    ["economy", "costs", "description"],
                    "Beskrivning av driftskostnad"
                  )}
                  {this.getValue(
                    ["economy", "costs", "insuranceDescription"],
                    "Beskrivning av försäkring"
                  )}
                  {this.getValue(
                    ["economy", "costs", "rentChanges"],
                    "Avgiftsförändringar"
                  )}
                  {this.getValue(
                    ["economy", "costs", "propertyTax"],
                    "Fastighetsskatt:",
                    "kr/år"
                  )}
                  {this.getValue(
                    ["estate", "shareInHousingAssociation"],
                    "Andelstal"
                  )}
                  {this.getValue(
                    ["estate", "shareInHousingAssociationContribution"],
                    "Andelstal insats"
                  )}
                  {this.getValue(
                    ["estate", "shareInHousingAssociationFee"],
                    "Andelstal avgift"
                  )}
                  {this.getValue(
                    ["estate", "shareInHousingAssociationDescription"],
                    "Andelstal kommentar"
                  )}
                  {this.getValue(
                    ["estate", "shareOfHousingAssociationNetDebt"],
                    "Bostadsrättens indirekta nettoskuldsättning",
                    "kr"
                  )}
                  {this.getValue(
                    ["estate", "shareOfHousingAssociationNetDebtDescription"],
                    "Beskrivning av Andel av BRF:s nettoskuldsättning"
                  )}
                  {this.getValue(
                    ["estate", "hasRepairFund", "translation"],
                    "Inre reparationsfond"
                  )}

                  {this.getValue(
                    ["estate", "repairFund"],
                    "Inre reparationsfond",
                    "kr"
                  )}
                  {this.getValue(
                    ["estate", "repairFundDescription"],
                    "Beskrivning av inre reparationsfond"
                  )}
                  {this.getValue(
                    ["estate", "repairFundCheckDate"],
                    "Senast kontrollerad"
                  )}
                  {this.getValue(["mortgage", "pawnedSetting"], "Pantsättning")}
                </div>

                {this.eval2(["estate", "construction"]) && (
                  <div>
                    <p className="fs-5   m-0 pb-1 font-serif text-uppercase  mt-5">
                      Byggnadssätt
                    </p>
                    {this.getValue(["estate", "buildingType"], "Byggnadstyp")}
                    {this.getValue(["estate", "buildYear"], "Byggnadsår")}
                    {this.getValue(
                      ["estate", "construction", "description"],
                      "Kommentar"
                    )}

                    {this.getValue(
                      ["estate", "construction", "subFoundationType"],
                      "Mark"
                    )}
                    {this.getValue(
                      ["estate", "construction", "foundationType"],
                      "Grund"
                    )}
                    {this.getValue(
                      ["estate", "construction", "frameType"],
                      "Stomme"
                    )}
                    {this.getValue(
                      ["estate", "construction", "joistType"],
                      "Bjälklag"
                    )}

                    {this.getValue(
                      ["estate", "construction", "facadeType"],
                      "Fasad"
                    )}
                    {this.getValue(
                      ["estate", "construction", "roofingType"],
                      "Takbeklädnad"
                    )}
                    {this.getValue(
                      ["estate", "construction", "guttersType"],
                      "Utv Plåtarbeten"
                    )}
                    {this.getValue(
                      ["estate", "construction", "windowType"],
                      "Fönster"
                    )}

                    {this.getValue(
                      ["estate", "ventilation", "type"],
                      "Ventilation"
                    )}
                    {this.getValue(
                      ["estate", "chimney", "type"],
                      "Skorstenstyp"
                    )}
                    {this.getValue(["estate", "plumbing", "water"], "Vatten")}
                    {this.getValue(["estate", "plumbing", "drain"], "Avlopp")}
                    {this.getValue(
                      ["estate", "heating", "type"],
                      "Uppvärmning"
                    )}
                    {this.getValue(["estate", "heating", "condition"])}
                    {this.getValue(
                      ["estate", "heating", "age"],
                      "",
                      "",
                      "Ålder: "
                    )}
                    {this.getValue(
                      ["estate", "heating", "brand"],
                      "",
                      "",
                      "Märke på värmeanläggning: "
                    )}
                    {this.getValue(
                      ["estate", "description"],
                      "Allmän information"
                    )}
                  </div>
                )}

                {this.state.deal["housingAssociation"] && (
                  <div style={{ whiteSpace: "pre-line" }}>
                    <p className="fs-5 lead m-0 pb-1 font-serif text-uppercase  mt-5">
                      Förening och hus
                    </p>
                    {this.getValue(["housingAssociation", "name"], "Förening")}
                    {this.getValue(
                      [
                        "housingAssociation",
                        "housingAssociationTaxationType",
                        "translation",
                      ],
                      "Taxeringstyp"
                    )}

                    {this.getValue(
                      ["housingAssociation", "founded"],
                      "Bildades"
                    )}
                    {this.getValue(
                      ["economy", "transferFee"],
                      "Överlåtelseavgift",
                      " kr"
                    )}
                    {this.getValue(
                      ["economy", "transferFeePaidBy", "translation"],
                      "Överlåtelseavgift betals av"
                    )}

                    {this.getValue(
                      ["economy", "pledgingFee"],
                      "Pantsättningsavgift",
                      " kr"
                    )}

                    {this.getValue(
                      ["housingAssociation", "generalInformation"],
                      "Allmän Information"
                    )}
                    {this.getValue(
                      ["housingAssociation", "buildingDescription"],
                      "Byggnad"
                    )}
                    {this.getValue(
                      ["housingAssociation", "otherAreasDescription"],
                      "Övriga utrymmen i föreningen"
                    )}
                    {this.getValue(
                      ["housingAssociation", "upcomingRenovationsDescription"],
                      "Kommande renoveringar i föreningen"
                    )}

                    {this.getValue(
                      [
                        "housingAssociation",
                        "legalEntityAcceptable",
                        "translation",
                      ],
                      "Juridisk person kan accepteras"
                    )}
                    {this.getValue(
                      ["location", "municipality", "name"],
                      "Kommun"
                    )}
                    {this.getValue(
                      ["objectType", "translation"],
                      "Byggnadstyp"
                    )}
                    {this.getValue(
                      ["housingAssociation", "plotOwnership", "translation"],
                      "Markägare"
                    )}

                    {this.getValue(
                      ["estate", "hasLift", "translation"],
                      "Hiss"
                    )}
                    {this.getValue(["parking", "description"], "Parkering")}
                    {this.getValue(
                      ["housingAssociation", "internetDescription"],
                      "Tv / Internet"
                    )}
                    {this.getValue(
                      ["housingAssociation", "heatingDescription"],
                      "Värme- och ventilationsanläggning"
                    )}
                  </div>
                )}

                {this.eval2(["housingAssociation", "energyPerformance"]) && (
                  <div>
                    <p className="fs-5   m-0 pb-1 font-serif text-uppercase  mt-5">
                      Energideklaration
                    </p>
                    {this.getValue(
                      [
                        "housingAssociation",
                        "energyPerformance",
                        "class",
                        "translation",
                      ],
                      "Energiklass"
                    )}
                    {this.getValue(
                      [
                        "housingAssociation",
                        "energyPerformance",
                        "performance",
                      ],
                      "Energiprestanda",
                      "kWh/kvm och år"
                    )}
                    {this.getValue(
                      [
                        "housingAssociation",
                        "energyPerformance",
                        "primaryPerformance",
                      ],
                      "Specifik energiprestanda",
                      "kWh/kvm och år"
                    )}
                    {this.getValue(
                      [
                        "housingAssociation",
                        "energyPerformance",
                        "status",
                        "translation",
                      ],
                      "",
                      "",
                      ""
                    )}
                    {this.getValue(
                      ["housingAssociation", "energyPerformance", "date"],
                      "",
                      "",
                      "Datum: "
                    )}
                    {this.getValue(
                      [
                        "housingAssociation",
                        "energyPerformance",
                        "description",
                      ],
                      "",
                      "",
                      ""
                    )}
                  </div>
                )}
                {this.eval2(["housingAssociation", "renovateDescription"]) && (
                  <div style={{ whiteSpace: "pre-line" }}>
                    <p className="fs-5 lead m-0 pb-1 font-serif text-uppercase  mt-5">
                      Utförda renoveringar
                    </p>

                    {this.getValue(
                      ["housingAssociation", "renovateDescription"],
                      ""
                    )}
                  </div>
                )}

                {this.eval2(["energyPerformance"]) && (
                  <div>
                    <p className="fs-5   m-0 pb-1 font-serif text-uppercase  mt-5">
                      Energideklaration
                    </p>
                    {this.getValue(
                      ["energyPerformance", "class", "translation"],
                      "Energiklass"
                    )}
                    {this.getValue(
                      ["energyPerformance", "performance"],
                      "Energiprestanda",
                      "kWh/kvm och år"
                    )}
                    {this.getValue(
                      ["energyPerformance", "primaryPerformance"],
                      "Specifik energiprestanda",
                      "kWh/kvm och år"
                    )}
                    {this.getValue(
                      ["energyPerformance", "status", "translation"],
                      "Sammanställning",
                      "",
                      ""
                    )}
                    {this.getValue(
                      ["energyPerformance", "date"],
                      "",
                      "",
                      "Datum: "
                    )}
                    {this.getValue(
                      ["energyPerformance", "description"],
                      "Beskrivning",
                      "",
                      ""
                    )}
                  </div>
                )}
                {this.eval2(["estate", "tvInternetDescription"]) && (
                  <div>
                    <p className="fs-5   m-0 pb-1 font-serif text-uppercase  mt-5">
                      Tv/Internet
                    </p>
                    {this.getValue(["estate", "tvInternetDescription"], "")}
                  </div>
                )}

                {this.eval2(["estate", "patioDescription"]) && (
                  <div>
                    <p className="fs-5   m-0 pb-1 font-serif text-uppercase  mt-5">
                      Uteplats
                    </p>
                    {this.getValue(["estate", "patioDescription"], "")}
                  </div>
                )}
                {(this.eval2(["estate", "otherBuildingsDescription"]) ||
                  this.eval2(["estate", "parkingDescription"])) && (
                  <div>
                    <p className="fs-5   m-0 pb-1 font-serif text-uppercase  mt-5">
                      Övriga byggnader/Parkeringsplats
                    </p>
                    {this.getValue(
                      ["estate", "otherBuildingsDescription"],
                      "Övriga byggnader"
                    )}
                    {this.getValue(
                      ["estate", "parkingDescription"],
                      "Parkering"
                    )}
                  </div>
                )}

                {this.eval2(["estate", "plotArea"]) && (
                  <div>
                    <p className="fs-5   m-0 pb-1 font-serif text-uppercase  mt-5">
                      Tomt/Servitut
                    </p>
                    {this.getValue(["estate", "plotArea"], "Tomtarea", "kvm")}
                    {this.getValue(["estate", "totalArea"], "Totalarea", "kvm")}
                    {this.getValue(
                      ["estate", "plotAreaAccuracy"],
                      "",
                      "",
                      "Tomareauppgifter enligt "
                    )}
                    {this.getValue(
                      ["estate", "plotDescription"],
                      "Beskrivning, Tomt"
                    )}
                  </div>
                )}
                {this.eval2(["easement", "easements"]) && (
                  <div>
                    {this.state.deal.easement.easements.map(function (item, i) {
                      return (
                        <div key={i}>
                          <p className="fs-5   mb-1">
                            <span className="fs-5   fw-normal m-0">
                              {item.type.translation}{" "}
                            </span>
                            {item.description}
                            <br />
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              {this.eval2(["objectType", "constant"]) ===
                "OBJECT_TYPE_FARMING" &&
                this.eval2(["buildings", "buildings"]) &&
                this.state.deal.buildings.buildings.length > 0 && (
                  <div>
                    <div className="container text-center w-25" id="fakta">
                      <h2 className="mt-5 fs-4 ">BYGGNADER</h2>
                      <ColoredLine color="#f9b361"></ColoredLine>
                    </div>
                    <div
                      style={{ whiteSpace: "pre-line" }}
                      className="faktawrapper "
                    >
                      <p className="fs-5 lead m-0 pb-1 font-serif text-uppercase  mt-5">
                        BESKRIVNING
                      </p>
                      <div className="">
                        {this.eval2(["buildings", "description"]) && (
                          <div style={{ whiteSpace: "pre-line" }}>
                            {this.getValue(["buildings", "description"], "")}
                          </div>
                        )}
                      </div>
                      {this.state.deal.buildings.buildings.map(function (
                        item,
                        i
                      ) {
                        return (
                          <div
                            style={{ whiteSpace: "pre-line" }}
                            key={i}
                            className=""
                          >
                            <p className="fs-5 lead m-0 pb-1 font-serif text-uppercase  mt-5">
                              {item.name}
                            </p>

                            <Field
                              data={item.description}
                              title="Beskrivning"
                            />

                            <Field data={item.buildYear} title="Byggnadsår" />
                            <Field
                              data={item.livingArea}
                              title="Boarea"
                              suffix="kvm"
                            />
                            <Field
                              data={item.otherLivingArea}
                              title="Biarea"
                              suffix="kvm"
                            />

                            <Field
                              data={item.costs?.propertyTax}
                              title="Fastighetsskatt"
                              suffix="kr/år"
                            />
                            <Field data={item.area} title="Area" suffix="kvm" />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              <div className="container text-center w-25">
                <h2 className="mt-5 fs-4 text-uppercase">
                  {this.eval2(["location", "residentialArea"])}
                </h2>
                <ColoredLine color="#f9b361"></ColoredLine>
              </div>

              <div className="container mt-5 mb-4">
                <div className="text-start cols2">
                  <p className="fs-5   ">
                    {this.eval2(["location", "description"])}
                  </p>
                </div>
              </div>
              {this.eval2(["location", "latitude"]) && (
                <div className="container">
                  <Map
                    lat={this.state.deal.location.latitude}
                    lng={this.state.deal.location.longitude}
                  ></Map>
                </div>
              )}

              {this.state.deal.files.length > 0 && (
                <div>
                  <div className="container text-center w-25" id="fakta">
                    <h2 className="mt-5 fs-4">DOKUMENT</h2>
                    <ColoredLine color="#f9b361"></ColoredLine>
                  </div>
                  <div className="container-sm faktawrapper">
                    {this.eval2(["objectType", "constant"]) ===
                      "OBJECT_TYPE_TENANT_OWNERSHIP" && (
                      <div className="pt-3">
                        <a
                          href="/img/Viktigt vid köp av bostadsrätt.pdf"
                          target="_blank"
                        >
                          <img
                            style={{ display: "inline-block" }}
                            src="/img/pdf.png"
                            alt="document icon"
                            width="25px"
                          />
                          <p
                            style={{ display: "inline-block" }}
                            className="fs-5   mb-1"
                          >
                            Viktigt vid köp av Bostadrätt
                          </p>
                        </a>
                      </div>
                    )}
                    {this.eval2(["objectType", "constant"]) ===
                      "OBJECT_TYPE_HOUSE" && (
                      <div className="pt-3">
                        <a
                          href="/img/Viktigt vid köp av fastighet.pdf"
                          target="_blank"
                        >
                          <img
                            style={{ display: "inline-block" }}
                            src="/img/pdf.png"
                            alt="document icon"
                            width="25px"
                          />
                          <p
                            style={{ display: "inline-block" }}
                            className="fs-5   mb-1"
                          >
                            Viktigt vid köp av Villa
                          </p>
                        </a>
                      </div>
                    )}
                    {this.state.deal.files.map(function (item, i) {
                      return (
                        <div key={i} className="pt-3">
                          <a href={item}>
                            <img
                              style={{ display: "inline-block" }}
                              src="/img/pdf.png"
                              alt="document icon"
                              width="25px"
                            />
                            <p
                              style={{ display: "inline-block" }}
                              className="fs-5   mb-1"
                            >
                              {item.split("/")[4]}
                            </p>
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              <Contentblock>
                <Footer></Footer>
              </Contentblock>
            </div>
          </div>
        )}
      </div>
    );
  }
}
