import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './antd.css';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import listings from './pages/listings';
import jobba from './pages/jobba';
import landing from './pages/landing';
import sell from './pages/sell';
import historia from './pages/historia';
import miljo from './pages/miljo';
import team from './pages/team';
import objekt from './pages/objekt';
import leif from './pages/leif';
import bo from './pages/bo';
import michael from './pages/michael';
import nyproduktion from './pages/nyproduktion';
import spanien from './pages/spanien';
import bostadguide from './pages/bostadguide';
import villaguide from './pages/villaguide';
import akademi from './pages/akademi';
import kopguidevilla from './pages/kopguidevilla';
import kopguidebostadsratt from './pages/kopguidebostadsratt';
import hasan from './pages/hasan';
import charbel from './pages/charbel';
import premiumvillas from './pages/premiumvilllas';
import alexander from './pages/alexander';
import ReactGA from "react-ga4";
import villa from './pages/villa';
import RouteChangeTracker from "./components/routechangetracker"
import ReactPixel from 'react-facebook-pixel';
import lottie from './pages/lottie';



function hashLinkScroll() {
  const { hash } = window.location;
  if (hash !== '') {
    // Push onto callback queue so it runs after the DOM is updated,
    // this is required when navigating from a different page so that
    // the element is rendered on the page before trying to getElementById.
    setTimeout(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) element.scrollIntoView();
    }, 0);
  }
}


function App() {

  return (
    <div className="no-scroll" style={{ overflow: "hidden" }}>
      <Router forceRefresh={true}
        onUpdate={hashLinkScroll}>
        <Switch>
          <Route exact path="/" component={landing} />
          <Route path="/listings" component={listings} />
          <Route path="/sell" component={sell} />
          <Route path="/jobba" component={jobba} />
          <Route path="/historia" component={historia} />
          <Route path="/miljo" component={miljo} />
          <Route path="/team" component={team} />
          <Route path="/objekt/:objektId" component={objekt} />
          <Route path="/villa/:objektId" component={villa} />

          <Route path="/leif" component={leif} />
          <Route path="/bo" component={bo} />
          <Route path="/lottie" component={lottie} />

          <Route path="/michael" component={michael} />
          <Route path="/nyproduktion" component={nyproduktion} />
          <Route path="/spanien" component={spanien} />
          <Route path="/bostadguide" component={bostadguide} />
          <Route path="/villaguide" component={villaguide} />
          <Route path="/akademi" component={akademi} />
          <Route path="/kopguidevilla" component={kopguidevilla} />
          <Route path="/kopguidebostadsratt" component={kopguidebostadsratt} />
          <Route path="/hasan" component={hasan} />
          <Route path="/charbel" component={charbel} />
          <Route path="/alexander" component={alexander} />
          <Route path="/premiumvillas" component={premiumvillas} />

          <Redirect to="/" />
          <RouteChangeTracker />

        </Switch>

      </Router>

    </div>
  );
}

export default App;
