export default function Field({
  data = "",
  title = "",
  suffix = "",
  prefix = "",
}) {
  console.log(data);
  if (!data) return null;
  return (
    <div>
      <p className="fs-5   mb-1">
        <span className="fs-5 fw-bolder m-0">
          {title ? title + ":" : null}{" "}
        </span>
        {prefix + data.toLocaleString() + " " + suffix}
        <br />{" "}
      </p>
    </div>
  );
}
